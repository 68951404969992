import React from 'react';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row", textAlign: "left" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Link to="/">
                <img src="/ata.png" alt="Company Logo"  className='main-logo' />
            </Link>
            <h1 style={{ color: "#ffffff" }}>About Us</h1>
            {/* <p style={{ fontStyle: "italic", marginBottom: "10px" }}>Innovation, Reimagined.</p> */}
            <hr style={{ border: "1px solid #ffffff", margin: "20px auto", width: "50%" }} />
            <div style={{ margin: "0 20%", color: "#ffffff" }}>
                <p>At ATA Investments, LLC, we seek to optimize the value of our investments by identifying opportunities that align with our overall goals. Our team of seasoned professionals is dedicated from acquisition to disposition.</p>

                <p>We believe that the key to successful investing lies in a combination of thorough market research, careful due diligence, and strategic planning. By taking a disciplined approach to investing, we are always aiming to ensue consistent returns while minimizing risk.</p>

                <p>Our mission is to utilize the best knowledge and resources to make informed decisions about investments. We are committed to helping you achieving our objectives through strategic investing.</p>
            </div>
        </div>
    </div>
  );
};

export default About;